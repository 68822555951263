import { validateChildFields, validateInlineSelect, validateName } from "@/utils/validators";
export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Insurer Name",
        required: true,
      },
      {
        model: "type",
        type: "input",
        inputType: "text",
        label: "Type (general, life, topup)",
        required: true,
      },
      {
        model: "slug",
        type: "input",
        inputType: "text",
        label: "Slug",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        validator: validateChildFields,
        schema: {
          fields: [
            {
              model: "gstin",
              type: "input",
              inputType: "text",
              label: "GSTIN",
            },
            {
              model: "bank_acc",
              type: "input",
              inputType: "text",
              label: "Bank Account Number",
            },
            {
              model: "bank_ifsc",
              type: "input",
              inputType: "text",
              label: "Bank IFSC Code",
            },
            {
              model: "bank_bene",
              type: "input",
              inputType: "text",
              label: "Beneficiary Name",
            },
            {
              model: "contact_person",
              type: "input",
              inputType: "text",
              label: "Contact Person",
            },
            {
              model: "contact_number",
              type: "input",
              inputType: "text",
              label: "Contact Number",
            },
            {
              model: "insurerType",
              type: "inline-select",
              label: "Type- PSU/ Private",
              options: ["PSU", "Private"],
              required: true,
              validator: ["required", validateInlineSelect],
            },
            {
              model: "endorsementTat",
              type: "input",
              inputType: "number",
              min: 0,
              label: "Endorsement TAT (in days)",
            },
            {
              model: "policyTat",
              type: "input",
              inputType: "number",
              min: 0,
              label: "Policy TAT (in days)",
            },
            {
              model: "fhplId",
              type: "input",
              inputType: "text",
              label: "Used during fhpl intimation, refer to data dictionary",
            },
            {
              model: "masterName",
              type: "input",
              inputType: "text",
              label: "Used during fhpl intimation, Official insurer name",
            },
          ],
        },
      },
    ],
  },
};
