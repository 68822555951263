<template lang="pug">
n-overlay.get-started.bg-alabaster
  div
    .custom-onboarding-container(v-if="isCustomOnboardingScreen")
      embed-screen(:link="generateCustomOnboardingLink()", :heading="getCustomOnboardingScreenTitle()", @back="triggerNextStep(-1)", @messageFromEmbededPage="recordIncomingEmbedFormData")
    div(v-else)
      .bg-white.w-100.z-1000.py-3.border-bottom-gray-400.border-bottom.border-1
        .d-flex.container.justify-content-between.align-items-center(v-if="!isMobileView")
          img.d-none.d-md-block.mr-6(:src="require('@/assets/images/insignia-black-tiber.svg')",alt="nova main logo",to="/")
          progress-indicator.bg-white.pb-0.flex-grow-1.d-flex.justify-content-center(:currentStepNumber="currentStepIndex + 1", :steps="onboardingScreens")
          span &nbsp;
        .d-flex.container.px-3.justify-content-between.align-items-center(v-else)
          div.d-flex.align-items-center
            i.icon-chevron-left.back-button.d-block.d-md-none(:disabled="isBackDisabled", @click="triggerNextStep(-1)", role="button")
            span.d-md-inline-flex.ml-3.text-gray-800.font-weight-semibold.font-lg {{ currentStepData.title }}
          span.text-gray-700 {{ `Step ${currentStepIndex + 1}/${onboardingScreens.length}` }}

      .w-100
        .container.justify-content-between
          .row
            .col-12.col-md-5
              div.mt-5.justify-content-center.w-75.d-none.d-md-block
                .hb1.text-teal-900.pb-2 {{ currentStepData.title}}
                span.text-gray-800 {{ currentStepData.description }}
                .about-alert-border.rounded.p-3.bg-white.my-3(v-if="currentStepData.note")
                  .d-flex.align-items-center
                    div
                      img(:src="require(`@/assets/images/callout-bulb.svg`)")
                    .ml-2
                      .font-sm.text-gray-900.mt-1 {{ currentStepData.note }}
              router-view.mb-0.mb-md-8.mt-5(name="left")

            .watermark.d-none.d-md-block
              img(:src="require('@/assets/images/insignia-watermark.svg')")
            .col-md-1
            .col-12.col-md-6.d-flex
              router-view.mb-8.mt-5(name="right")
        base-footer.d-block.border-top-gray-400.border-top.border-2(
          variant="success",
          :buttonText="getContinueButtonText()",
          :isButtonDisabled="isContinueButtonDisabled",
          :showBackButton="!isBackDisabled",
          @goBack="triggerNextStep(-1)",
          @continueFlow="triggerNextStep(+1)")
    onboarding-success-modal
</template>

<script>
import gql from "graphql-tag";
import { isEmpty, set } from "lodash";
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";
import NIcon from "../../../../components/NovaIcon.vue";
import CheckupFooter from "../../checkup/components/CheckupFooter.vue";
import ProgressIndicator from "../../checkup/components/ProgressIndicator.vue";
import NPolicyCard from "../../components/PolicyCard.vue";
import CardLink from "../claims/components/CardLink.vue";
import NModal from "../../../../components/NovaModal.vue";
import BaseFooter from "../../components/BaseFooter.vue";
import ProgressCard from "./components/ProgressCard.vue";
import DependentDetailsWrapper from "./components/DependentDetailsWrapper.vue";
import OnboardingSuccessModal from "./components/OnboardingSuccessModal.vue";
import AddDependentsCard from "./components/AddDependentsCard.vue";
import MiniPolicyCard from "./components/MiniPolicyCard.vue";
import CardButton from "./components/CardButton.vue";
import OtpModal from "./OtpModal.vue";
import mixin from "./mixin";
import NTopNavbar from "@/layout/NovaTopNavbar.vue";
import SpecialModal from "@/components/SpecialModal.vue";
import { shouldShowOnboardingFlow } from "@/utils";
import AddDependentsWrapper from "@/components/AddDependents/AddDependentsWrapper.vue";
import NFooter from "@/layout/Footer.vue";
import NOverlay from "@/components/Overlay";
import NButton from "@/components/NovaButton.vue";
import EmbedScreen from "@/components/EmbedScreen.vue";

export default {
  components: {
    NButton,
    NFooter,
    ProgressCard,
    NOverlay,
    AddDependentsWrapper,
    NPolicyCard,
    SpecialModal,
    DependentDetailsWrapper,
    OnboardingSuccessModal,
    AddDependentsCard,
    MiniPolicyCard,
    NTopNavbar,
    CardLink,
    NModal,
    CardButton,
    EmbedScreen,
    OtpModal,
    ProgressIndicator,
    CheckupFooter,
    BaseFooter,
    NIcon,
  },
  mixins: [mixin],
  data() {
    return {
      isContinueButtonDisabled: false,
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    isBackDisabled() {
      return this.$route.path.includes("/about");
    },
    buttonTextForCurrentStep() {
      return this.onboardingScreens[this.currentStepIndex]?.buttonText || "Continue";
    },
    currentStepData() {
      return this.onboardingScreens[this.currentStepIndex] || {};
    },
    isUserOrgAdmin() {
      return this.user?.roles?.includes("org_admin");
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.beforeUnload);
    this.$store.commit("getStarted/setSkipDependentAddition", false);
    await this.fetchUserData();
    this.showLoader();
    if (!shouldShowOnboardingFlow(this.$store?.state || {})) {
      this.$router.push({ name: "error", params: { type: "unauthorized" } });
    }
  },
  mounted() {
    this.$router.push("/user/get-started");
    this.computeObSteps();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
  methods: {
    beforeUnload(e) {
      e.preventDefault();
      const confirmationMessage = "Are you sure you want to leave this page. All unsaved changes will be lost?";
      e.returnValue = confirmationMessage;
    },
    transformLabel(label) {
      if (this.isUserOrgAdmin) {
        return `${label}<span style="color:red">*</span>`;
      }
      return label;
    },
    async validateForm() {
      await this.$refs?.formData?.validate();
    },
    triggerNextStep: debounce(
      async function (step) {
        this.isContinueButtonDisabled = true;
        await this.nextStep(step);
        this.isContinueButtonDisabled = false;
      },
      500,
      { leading: true, trailing: false }
    ),
    getScreenIndex(screenKey) {
      return Object.values(this.onboardingScreens).findIndex((screen) => screen.key === screenKey) + 1;
    },
    getContinueButtonText() {
      if (this.$route.path.includes("/review")) {
        return "Confirm Selection";
      }
      return "Continue ->";
    },
    showLoader() {
      this.loading = true;
      // auto hide is needed because apollo errors do not return
      // control to the calling method
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    logStepInPosthog() {
      const user = this.user || this.$store?.state?.user;
      if (!user) return;
      const eventProperties = {
        employee_email: user.email,
        day_of_month: new Date().getDate(),
        org_name: user.org.name,
      };
      if (this.isDepScreen) {
        eventProperties.dependent_count = user.dependents.length;
      }
      window.posthog.capture(this.onboardingScreens[this.currentStepIndex].posthogEvent, eventProperties);
    },
    shouldShowOtpModal() {
      if (!this.isOtpEnabled) return false;
      if (!this.checkIfCurrentScreen(this.$options.screens.ABOUT)) return false;
      if (this.isOtpVerified) return false;
      return true;
    },
    async markFlowAsCompleted() {
      // TODO: Put checks to ensure all the required data has been filled
      // before executing below lines
      this.profileModel.meta.showOnboardingScreens = false;
      const shouldMapUserAndDependentsToBenefit = this.benefitsRelatedToUser.length > 0;
      await this.saveUserDetails(shouldMapUserAndDependentsToBenefit);
    },
    async markTokenAsUsed() {
      const userToken = this.$store.getters.getUserLoginToken;

      if (!userToken) return;

      await this.$apollo.mutate({
        mutation: gql`
          mutation markTokensAsUsed($email: String!, $token: String!) {
            markTokensAsUsed(input: { token: $token, email: $email }) {
              email
            }
          }
        `,
        variables: {
          email: this.$store.getters.user.email,
          token: userToken,
        },
      });
    },
    generateCustomOnboardingLink() {
      if (!this.isCustomOnboardingEnabled) {
        return "";
      }
      const embedAppLink = this.user?.org?.meta?.customOnboardingUrl;
      const urlPrams = new URLSearchParams();
      this.benefitsRelatedToUser.forEach((benefit) => {
        if (!benefit.meta?.isFlexi) {
          return;
        }

        benefit.meta?.requiredFields?.forEach((requiredField) => {
          if (urlPrams.has(requiredField)) {
            return;
          }
          const urlParam = this.user[requiredField] || this.user.meta[requiredField];
          urlPrams.append(requiredField, urlParam);
        });
      });
      const embedUrl = `${embedAppLink}#${urlPrams.toString()}`;
      return embedUrl;
    },
    getCustomOnboardingScreenTitle() {
      const customTitle = this.user?.org?.meta?.customOnboardingTitle?.toString().trim();
      return customTitle || "Customize Your Coverage";
    },
    async recordIncomingEmbedFormData(message) {
      if (message?.type !== "custom-onboarding") {
        return;
      }
      this.$store.commit("clearToasts");
      if (isEmpty(message?.data)) {
        await this.triggerNextStep(+1);
        return;
      }
      try {
        const newJobGrade = message?.data?.grade;
        this.updatedJobGradeFromCustomOnboarding = newJobGrade;
        this.$store.commit("getStarted/updateSelfMetaDetails", { jobGrade: newJobGrade });
        set(this.profileModel, "meta.jobGrade", newJobGrade);
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation CreateFormResponse($meta: JSONObject!, $formSlug: String) {
              upsertFormResponse(input: { meta: $meta, formSlug: $formSlug }) {
                formResponse {
                  id
                  __typename
                }
                __typename
              }
            }
          `,
          variables: {
            meta: message?.data,
            formSlug: "custom-onboarding",
          },
        });
        if (result.data?.upsertFormResponse?.formResponse?.id) {
          // Refetch the user, it might have changed data based on custom-onboarding screen inputs
          this.$store.commit("getStarted/setSessionProps", {
            updatedJobGradeFromCustomOnboarding: this.updatedJobGradeFromCustomOnboarding,
          });
          await this.fetchUserData();
          // if successfully submitted the form, redirect to next screen
          await this.triggerNextStep(+1);
          this.$store.commit("addToast", {
            variant: "success",
            message: "You have successfully customised your benefits",
          });
          return;
        }
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Benefits customisation failed. Please contact Nova support.",
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.main-container {
  margin-left: 10rem;
  margin-right: 10rem;
}
.dependents-wrapper {
  min-height: 400px;
  max-height: 500px;
  overflow: auto;
  width: 100%;
}
.watermark {
  position: fixed;
  bottom: 9%;
  left: 0;
  z-index: -1;
}
.policies-wrapper {
  position: relative;
  min-height: 500px;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.group-img {
  position: absolute;
  left: 22rem;
  bottom: 0;
}
.policy-scroll {
  height: calc(100vh - 16rem);
  overflow-y: auto;
}
.dependents-scroll {
  height: 25rem;
  overflow-y: auto;
  overflow-x: none;
  @include media-breakpoint-down(sm) {
    height: calc(100vh - 18rem);
  }
}
.policy-list {
  max-height: 75vh;
}
.left-container {
  height: 91vh;
  @include media-breakpoint-down(sm) {
    height: auto;
  }
}
.top-strip {
  color: $white;
  height: 8rem;
  padding: 2rem;
  .subtitle-text {
    letter-spacing: 0.02em;
  }
  @include media-breakpoint-down(sm) {
    height: 10rem;
  }
}
.about-alert-border {
  border: 1.5px solid $teal-600;
}
.detail-border-bottom {
  border-bottom: 1px solid $gray-200;
}
.confirm-button {
  width: 25rem;
}

#otp-verification-modal___BV_modal_content_ {
  max-width: 480px;
  max-height: 478px;
}
.otp-special-modal {
  padding-bottom: 0rem !important;
}
.icon {
  object-fit: contain;
  margin-right: 10px; /* Adjust as needed */
}

.text-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
